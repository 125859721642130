<template>
    <div class="sidebar-content-inner">
        <slot />
    </div>
</template>

<style lang="scss">
.sidebar-content-inner {
    @include sidebar-content;

    position: relative;
}
</style>
