<script setup>
const props = defineProps({
    text: {
        type: String,
        default: ''
    }
});

const { $md } = useNuxtApp();
const htmlString = props.text ? $md.renderInline(props.text) : '';
</script>

<template>
    <h2 class="sidebar-main-headline" v-html="htmlString" />
</template>

<style lang="scss">
.sidebar-main-headline {
    @include superheadline-h1;

    margin-block-end: px2rem(40);
    text-align: center;
    display: inline-block;

    @include bp(medium1) {
        font-family: $font-body;
        font-weight: 800;
        font-size: px2rem(20);
        line-height: px2rem(25);
        padding-inline: 0 0;
        letter-spacing: 0;
        display: block;
        width: 100%;
    }

    &::before {
        @include bp(medium1) {
            display: none;
        }
    }

    br {
        display: none;
    }

    em {
        display: block;

        @include bp(medium1) {
            font-family: $font-body;
            font-weight: 800;
            font-size: px2rem(20);
            line-height: px2rem(25);
            font-style: normal;
            display: inline;
        }
    }
}
</style>
